import { assign, forEach, cloneDeep } from "lodash";
import CryptoJS from "crypto-js";
import SimpleCrypto from "simple-crypto-js";
import { NO_THUMBNAIL_FOUND } from "@/assets/icons/icons";
import { $toastr } from "./alerts";
import localStorage from "./localStorage";
import userAgent from "./user-agent";

const jwt = require("jwt-decode");

let meta = {
  message: "Thank you for using our application",
  hasEvent: false,
  cantChangeRoute: false,
  cb: false,
};

const openRoutes = [
  "login",
  "home",
  "search",
  "sso",
  "conference-login",
  "forgot-password",
  "create-password",
  "verify-otp",
  "public-viewer",
  "registration",
  "public-presentation-viewer",
  "public-case-viewer",
  "public-take-assessment",
  "public-review-assessment",
  "about",
  "contact",
  "terms",
  "features",
  "conference-user-registration",
  "google-auth",
  "app-fail",
  "public-terms",
];
let session = {
  id: process.env.VUE_APP_DATA_KEY,
  token: null,
  tokenData: null,
  tokenExpiry: null,
  userData: null,
  homeData: null,
  tenantData: null,
  siteData: null,
  sasTokens: [],
  apiConfiguration: null,
  subscriberInformation: null,
  permissions: null,
  userAgent,
};

const generateKey = (secret) =>
  CryptoJS.SHA256(secret).toString(CryptoJS.enc.Base64);
const simpleCrypto = new SimpleCrypto(generateKey(session.id)); // Same key as React publicsite
// Decode Token

const decodeToken = (arg) => {
  const data = arg || session.token;
  if (!data) return null;
  return jwt(data);
};

const getSessionSecret = () => session.id;

const encryptData = (data) =>
  simpleCrypto
    .encrypt(data)
    .replace(/[/]/g, "ppslash")
    .replace(/[+]/g, "ppplus");

const decryptData = (data) => {
  try {
    if (data != null) {
      const replacedData = data
        .replaceAll("ppslash", "/")
        .replaceAll("ppplus", "+");
      return simpleCrypto.decrypt(replacedData);
    }
  } catch (err) {
    $toastr(
      "error",
      "Link you are trying to access is broken link, please enter your credentials to use platform."
    );
    window.setTimeout(() => {
      logout(); // eslint-disable-line
      window.location.reload();
    }, 1000);
  }
  return null;
};

// Meta getters and setters

const setMeta = (_meta) => {
  meta = {
    ...meta,
    ..._meta,
  };
};

const getMeta = () => meta;

const resetMeta = () => {
  meta = {
    message: "Thank you for using our application",
    hasEvent: false,
    cantChangeRoute: false,
    cb: false,
    customMessage: "",
  };
};

// Session Token Getters and Setters

const getToken = () =>
  session.token ? session.token : localStorage.getItem("token");

const setToken = (data) => {
  if (!data) return;
  const decoded = decodeToken(data);
  localStorage.removeItem("token");
  localStorage.setItem("token", data);
  session = assign(session, {
    token: data,
    tokenData: decoded,
    tokenExpiry: decoded && decoded.exp ? decoded.exp : null,
  });
};

const getUserAgent = () =>
  session && session.userAgent ? session.userAgent : null;

const getSasTokens = (resourceId, containerName) => {
  if (!resourceId || !containerName) return null;
  const { sasTokens } = session;
  let token = null;
  forEach(sasTokens, (item) => {
    if (
      item.resourceId === resourceId &&
      item.containerName === containerName
    ) {
      token = `?${item.sasToken}`;
    }
  });

  return token;
};

const setSasTokens = (sasTokens) => {
  if (!sasTokens) return;
  session = {
    ...session,
    sasTokens,
  };
};

const getResource = (src, resourceId, containerName, altImage) => {
  if (!src || !resourceId || !containerName) {
    return altImage || NO_THUMBNAIL_FOUND;
  }
  const token = getSasTokens(resourceId, containerName);
  return `${src}${token}`;
};

const setApiConfigurations = (apiConfiguration) => {
  if (!apiConfiguration) return;
  session = {
    ...session,
    apiConfiguration,
  };
};

const setPermissions = (data) => {
  if (!data) return;
  session = {
    ...session,
    permissions: data,
  };
};

const getApiConfigurationsserData = () => session.apiConfiguration;
const getPermissions = () => session.permissions;

// Tenant Getters and Setters

const getTenantData = () => (session.tenantData ? session.tenantData : null);

const setTenantData = (data) => {
  if (!data) return;
  session.tenantData = data;
};

// Tenant Config Getters and Setters

const getTenantConfigData = () =>
  session.tenantConfigData ? session.tenantConfigData : null;

const setTenantConfigData = (data) => {
  if (!data) return;
  session.tenantConfigData = data;
};

const getSiteData = () => (session.siteData ? session.siteData : null);

const setSiteData = (data) => {
  if (!data) return;
  session.siteData = data;
};

// Decoded token data

const getTokenData = () => {
  const data = getToken();
  return session.tokenData || decodeToken(data);
};

// User Getters and Setters

const getUserData = () => (session.userData ? session.userData : null);

const setUserData = (data) => {
  if (!data) return;
  session.userData = {
    ...data,
    userAppAccess: getTokenData().userAppAccess,
  };
};

// Home Getters and Setters

const getHomeData = () => (session.homeData ? session.homeData : null);

const setHomeData = (data) => {
  if (!data) return;
  session.homeData = {
    ...data,
  };
};

// Subscription Getters and Setters

const getSubscriptionData = () =>
  session.subscriberInformation ? session.subscriberInformation : null;

const setSubscriptionData = (data) => {
  if (!data) return;
  session.subscriberInformation = data;
};

// Check Token Expiry

const isTokenExpired = () => {
  if (!session.token) return false;
  const expiry = session.tokenExpiry
    ? session.tokenExpiry
    : getTokenData(session.token).exp;
  return expiry < Date.now() / 1000;
};

// Redirect To

const updateSessionData = (data) => {
  if (!data) return;
  if (data.userData) {
    setUserData(data.userData);
  }
  if (data.homeData) {
    setHomeData(data.homeData);
  }
  if (data.tenantData) {
    setTenantData(data.tenantData);
  }
  if (data.sasTokens) {
    setSasTokens(data.sasTokens);
  }
  if (data.tenantConfigData) {
    setTenantConfigData(data.tenantConfigData);
  }
  if (data.siteData) {
    setSiteData(data.siteData);
  }
  if (data.apiConfiguration) {
    setApiConfigurations(data.apiConfiguration);
  }
  if (data.permissions) {
    setPermissions(data.permissions);
  }
  if (data.subscriberInformation) {
    setSubscriptionData(data.subscriberInformation);
  }
  return cloneDeep(session); // eslint-disable-line
};

const logout = () => {
  if (!session.token) return null;
  session = assign(session, {
    token: null,
    tokenData: null,
    tokenExpiry: null,
    userData: null,
  });
  localStorage.removeAll();
  return session;
};

const login = (data) => {
  const { token, userData, subscriberInformation, tenantConfigData } = data;
  localStorage.removeAll();
  setToken(token);
  updateSessionData({
    userData,
    subscriberInformation,
    tenantConfigData,
  });
  localStorage.setItem("login", true);
  return {
    data: session,
  };
};

const checkSession = () => {
  const token = localStorage.getItem("token");
  setToken(token);
  return token;
};

const getUserRole = () =>
  session && session.userData ? session.userData.roles[0].role : null;

window.document.body.classList.add(userAgent.osClass);
window.document.body.classList.add(userAgent.browserClass);

export default {
  getMeta,
  setMeta,
  resetMeta,
  getSessionSecret,
  isTokenExpired,
  getToken,
  checkSession,
  setToken,
  setSiteData,
  setUserData,
  setHomeData,
  setTenantData,
  getUserData,
  getHomeData,
  getTenantData,
  getSiteData,
  getTokenData,
  openRoutes,
  logout,
  login,
  getUserRole,
  getUserAgent,
  encryptData,
  decryptData,
  updateSessionData,
  setSasTokens,
  getSasTokens,
  getResource,
  getTenantConfigData,
  setTenantConfigData,
  getApiConfigurationsserData,
  setApiConfigurations,
  getPermissions,
  setPermissions,
  getSubscriptionData,
  setSubscriptionData,
};
