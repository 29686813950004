import { debounce } from 'lodash';
import { $modalAlert } from './alerts';
import localStorage from './localStorage';
import { $session } from '@/helpers';

let userActivityInterval = null;
let isUploading = false;
const MAX_TIME = 600;
const INTERVAL_TIME = 60000;
const MAX_TIME_MILLI_SECONDS = MAX_TIME * INTERVAL_TIME;
const DEBOUNCE_TIME = 1000;
const IDLE_TIME_OUT_KEY = 'idle-time';
// const TIME_DIFFRENCE = MAX_TIME - 1;

const resetTimer = debounce(() => {
  // console.log('Performed Activity', new Date());
  localStorage.setItem(IDLE_TIME_OUT_KEY, new Date().getTime());
}, DEBOUNCE_TIME);


const activateActivityTracker = () => {
  localStorage.setItem(IDLE_TIME_OUT_KEY, new Date().getTime());
  // console.log('Started Timeout', new Date());
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('scroll', resetTimer);
  window.addEventListener('keydown', resetTimer);
  window.addEventListener('resize', resetTimer);
};

export const setIsUploading = enable => {
  isUploading = enable;
};

export const deactivateActivityTracker = () => {
  clearTimeout(userActivityInterval);
  window.removeEventListener('mousemove', resetTimer);
  window.removeEventListener('scroll', resetTimer);
  window.removeEventListener('keydown', resetTimer);
  window.removeEventListener('resize', resetTimer);
  localStorage.removeItem(IDLE_TIME_OUT_KEY);
};

export default cb => {
  if (userActivityInterval) {
    deactivateActivityTracker();
  }
  activateActivityTracker();
  userActivityInterval = setInterval(() => {
    const currentPath = window.location.pathname;
    const isPublicSite = ['/',
      // '/home', 
      '/email-verification', 
      '/create-password'
    , '/verify-otp', 
    '/forgot-password', 
    '/about', 
    // '/news-details', 
    // '/news-all', 
    // '/mission-and-overview', 
    // '/financials', 
    // '/our-sponsors', 
    // '/faqs',
    // '/contact-us',
    // '/terms-of-use', 
    // '/leadership', 
    // '/tss-experts',
    // '/sitemap', 
    // '/news-and-events',
    // '/news-and-events-details',
    // '/donate',
    // '/video-series',
    // '/brochure',
    // '/video-series-details',
    // 'thyroid' 
  ].includes(currentPath);
    if (isPublicSite) {
      return;
    }    
    // const isPublicSiteWithQuery = ['/search', '/submitcase','/search-result','/search-cohot','/search-microscopic'].some(path => currentPath.startsWith(path));
    // if (isPublicSiteWithQuery ) {
    //   return;
    // }
     const encryptedIsPublicSite = currentPath.split("/")[7];
     if(encryptedIsPublicSite !== undefined)
     {
      const isViewerFromPublicSite = $session.decryptData(encryptedIsPublicSite);
      if(isViewerFromPublicSite)
      {
        return;
      }
    }
    
    const time = localStorage.getItem(IDLE_TIME_OUT_KEY);
    const timer = Number(time);
    const currentTime = Number(new Date().getTime());
    const timedOut = (currentTime - timer) > (MAX_TIME_MILLI_SECONDS);
    if (timedOut) {
      if (isUploading) {
        resetTimer();
        return;
      }
      // console.log('Time out', new Date());
      $modalAlert('info', `There was no activity performed by you in last ${MAX_TIME} mins, your session is expired`, () => {
        window.location.reload();
      });
      if (cb) cb();
      deactivateActivityTracker();
    }
  }, INTERVAL_TIME);
};
