export default {
  next: 'Next',
  link: 'Link',
  type: 'Type',
  host: 'Host',
  port: 'Port',
  reset: 'Reset',
  message: 'Message',
  profile: 'Profile',
  preview: 'Preview',
  twitter: 'Twitter',
  aboutUs: 'About us',
  addUser: 'Add User',
  activate: 'Activate',
  facebook: 'Facebook',
  linkedIn: 'Linkedin',
  newUsers: 'New Users',
  accessKey: 'Access Key',
  deactivate: 'Deactivate',
  uploadTime: 'Upload Time',
  socialLinks: 'Social Links',
  bannerImage: 'Banner Image',
  accountName: 'Account Name',
  inviteUsers: 'Invite Users',
  showViewers: 'Show Viewers',
  organization: 'Organization',
  organization_sm: 'organization',
  contactEmail: 'Contact Email',
  providerType: 'Provider type',
  resourceType: 'Resource Type',
  aboutUsImage: 'About us Image',
  watermarkLogo: 'Watermark Logo',
  publishTenant: 'Publish Tenant',
  containerName: 'Container Name',
  onboardClient: 'Onboard client',
  modulesAccess: 'Modules Access',
  addSocialLink: 'Add Social Link',
  markAsDefault: 'Mark as default',
  emailAddresses: 'Email Addresses',
  changePassword: 'Change Password',
  applicationType: 'Application Type',
  applicationLink: 'Application Link',
  addConfiguration: 'Add Configuration',
  updateConfiguration: 'Update Configuration',
  databaseUsername: 'Database Username',
  databasePassword: 'Database Password',
  groupDescription: 'Group Description',
  resendInvitation: 'Re-Send Invitation',
  changePasswordEndPoint: 'Change password end point',
  singleSignOnEndPoint: 'Login end point',
  forgotPasswordEndPoint: 'Forgot password end point',
  searchConfiguration: 'Search configuration',

  // views
  updateImage: 'Update Image',
  uploadImage: 'Upload Image',
  ShareResetPasswordLink: 'Share reset password link to user',
  updateLogo: 'Update Logo',
  updateWatermarkLogo: 'Update Watermark Logo',
  uploadLogo: 'Upload Logo',
  uploadWatermarkLogo: 'Upload Watermark Logo',
  cloudType: 'Cloud Type',
  default: 'Default',
  addResources: 'Add Resources',
  hostName: 'Host Name',
  smtpPlaceholder: 'smpt.xxxx.com',
  portPlaceholder: '58xx',
  smtpUsername: 'SMTP Username',
  smtpPassword: 'SMTP Password',
  domain: 'Domain',
  domainPlaceholder: 'example.com',
  enterValidDNS: 'Please enter valid dns name',
  smtpConfiguration: 'Smtp Configuration',
  draft: 'Draft',
  pending: 'Pending',
  invited: 'Invited',
  updateUser: 'Update User',
  atleastOneStorageAccount: 'There should be atleast one storage account by default.',
  fillPersonalDetails: 'Please fill all your personal details before using application.',
  resetPassword: 'Reset Password',
  userCode: 'User Code',
  enterUserCode: 'User Code',
  emailApprove: 'Your email verification has been completed. Editor will review your request, once it approved you will get email notification to access the platform.',   
  approved: 'Approved',
  approve: 'Approve',
  reject: 'Reject',
  codeAlreadyExists : 'User code already exists, please try with another code.',
  addsubscription: 'Add Subscription',
  updatesubscription: 'Update Subscription',
  sponsorImage: 'Sponsor Image',
  newsImage: 'News Image',
  addSpecificCohort: 'Add Specific Cohort',
  updateSpecificCohort: 'Update Specific Cohort',
  project: 'Project',
  selectFor:'Select For',
  selectRole: 'Role',
  regionalEditor: 'Regional Editor',
  chairPersonAlreadyExists : 'Only one chairperson allowed with selected tumor site.',
  emailAddress: 'Email Address',
  updateThumbnail: 'Update Thumbnail',
};
