const AuthModule = () => import("./AuthModule.vue");

export default {
    path: "/",
    component: AuthModule,
    children: [
        {
            path: "login",
            name: "login",
            component: () => import("./views/Login.vue"),
        },
        {
            path: "forgot-password",
            name: "forgot-password",
            component: () => import("./views/ForgotPassword.vue"),
        },
        {
            path: 'verify-otp/:id',
            name: 'verify-otp',
            component: () => import("./views/VerifyOTP.vue"),
        },        
        {
            path: "create-password",
            name: "create-password",
            component: () => import("./views/CreatePassword.vue"),
        },
        {
            path: "email-verification/:id",
            name: "email-verification",
            component: () => import("./views/Email-Verification.vue"),
        },
        {
            path: "/",
            redirect: {
                name: "login",
            },
        },
    ],
};
