export default {
  // Landing Page
  heroHeader: 'Defeating cancer together through education, science and better diagnosis worldwide',
  heroDetails: 'The World Tumor Registry offers a comprehensive, searchable and continually updated open-access catalogue of microscopic images of human cancers occurring in every geographic region of the world',
  heroCollectionOfTumor: 'COLLECTION OF TUMORS',
  heroTagline: 'CONTRIBUTED BY EVERYONE, CURATED BY EXPERTS',
  
  // WTR 
  addToEditorialBox: 'Add to Editorial Box',

  //WTR END

  linkToWiydx: 'Link to Wiydx',
  noOfAttempts: 'No.of attempts',
  backToSlides: 'Back To Slides',
  pageNotFound: 'Page not found',
  noOfQuestions: 'No.Of Questions',
  enterTextHere: 'Enter text here',
  enterGroupName: 'Enter group name',
  enterYourName: 'Please enter your name',
  enterYourContact: 'Please enter your contact no',
  enterYourAddress: 'Please enter your address',
  enterAnswerKey: 'Enter Answer key',
  selectGroup: 'Please select group',
  reEnterPassword: 'Re-enter password',
  enterOldPassword: 'Enter old password',
  enterNewPassword: 'Enter new password',
  showDigitalSlide: 'Show Digital Slide',
  addEmailAddresses: 'Add Email addresses',
  pleaseUploadAgain: 'Please upload again',
  setDurationInMins: 'Set Duration',
  addToSlideLibrary: 'Add to Slide Library',
  saveInQuestionBank: 'Save in Question bank',
  bannerHeaderContent: 'Banner Header Content',
  youAreAboutToShare: 'You are about to share',

  validFilesSelected: 'Valid files selected',
  noAnnotationsFound: 'No Annotations found',
  enterSectionName: 'Please enter section name',
  theOptionIsRequired: 'The option is required',
  thisFieldIsRequired: 'This field is required',
  pendingForEvaluation: 'Pending For Evaluation',
  supportedFileFormats: 'Supported File Formats',
  pleaseSelectAnOption: 'Please select an option',
  enterEmailAddress: 'Please, enter email address',
  pleaseEnterSocialLink: 'Please enter social link',
  pleaseGiveTheFeedback: 'Please provide the feedback',
  alreadyHaveAnAccount: 'I already have an account',
  typeYourMessageHere: 'Type your message here ....',
  showWaterMark: 'Show watermark in viewer snapshot',
  dicomDcmRadiologyImages: 'Dicom(dcm)/ radiology images',
  proceedHereToLogin: 'Please proceed here to the login',
  pendingSlides: 'Pending Slides which cannot be shared',
  assessmentIsNotAvailable: 'Assessment is not available',
  upload540X440PixelsImage: 'Upload 540X440 pixels image',
  CantEditImportedQuestion: "Can't edit Imported Question",
  theValidFrom: 'The valid from and valid to are required',
  copyFirstSlide: 'Copy first slide details to all slides',
  upload1366X570PixelsImage: 'Upload 1366X570 pixels image',
  theQuestionTypeIsRequired: 'The question type is required',
  pleaseEnterMessageToUsers: 'Please enter message to users',
  theNumberOfQuestions: 'The number of questions is required',
  maximumOf1000Records: 'maximum of 1000 records can be shown',
  enterDescription: 'Please enter description of presentation',
  designedByPhysicians: 'Designed By Physicians For Physicians',
  thisIsAboutYourOrganisation: 'This is about your organisation',
  windowsMacOSXUbuntuAndroid: 'Windows, MacOSX, Ubuntu, Android',
  certificationAfter: 'Certification after completion of assessment',
  copySlideNameToDiagnosisField: 'Copy slide name to diagnosis field',
  showSlideNameInViewer: 'Show slide name in viewer during presentation',
  fillAllValidDetails: 'Please fill all valid details before continuing',
  theOptionField: 'The option field may not be greater than 1024 characters',
  filesWhichAreStillAboutToProcess: 'Files which are still about to process',
  youHaveSetYourPassword:
    'You have set your password and activated the account',
  theExplanationField:
    'The explanation field may not be greater than 1024 characters',
  enterEmailAddressForPasscode:
    'Please enter your email address to get a 6 digit passcode.',
  onlyMultipleChoiceQuestions:
    'Only multiple choice questions can be added for an assessment',
  shortQuestionsWillBeExcluded:
    'The Short Answer type questions will be excluded from score calculation',
  checkSpam:
    '#Note: If you will not receive any email under your inbox, please check your spam box.',
  inFoldersPleaseZipFolder:
    'select to upload the root folder in which all dicom images (DCM) for the respective series are located. Upload of zip files and other file formats are not enabled or supported.',
  verificationMailWillBeSentToEmailId:
    'Verification mail will be sent to email id which you have entered once you click on register',
  filesPleaseUploadASingleZipFile:
  'files, please upload zip files only (direct .mrxs/.dcm file upload will not be supported)',
    // 'files, please upload a single zip file including the MRXS file and associated folder (select both the file and associated folder, with both selected, right click and send to>compressed file)',
  thisIsWTRPlatform:
    'This is a World Tumor Registry (WTR) platform and should be used only for educational and exploratory purposes, not for direct diagnostic use. You are responsible for the accuracy of the information uploaded to WTR. Although every effort has been made to de-identify patient health information from uploaded whole slides and other images, please verify that you do not see any images that display patient information on them after uploading them and before making them public. Any label and primary metadata associated with a whole slide pathology image will not be displayed in WTR. Patient information that should not be displayed includes name, address (all geographic subdivisions smaller than state, including street address, city-county, and zip code), all elements (except years) of dates related to an individual (including birth date, admission date, discharge date, date of death, and exact age if over 89), telephone numbers, fax number, email address, social security number, medical record number, health insurance number, account number, certificate or licence number, any vehicle or other device serial number, finger or voice print, photographic image (not limited to images of the face), or any other characteristic that could uniquely identify the individual.',
 
  mapSubmitNotes :
  'WTR accepts high quality de-identified whole-slide images, and collects the following information: histopathological diagnosis, patient demographics (sex and age), geographic region, country, state/province, tumor staging information (T, N, M categories), cytology, immunohistochemistry, genetics, environmental settings, and familial disease.',
    // My Groups -  create group
  updatedSuccessfully: 'Updated Successfully',
  successfullyMsg: '{event} Successfully',
  notExist: '{item} does not exist',
  cannotAddYourself: 'You cannot add yourself',
  userAlreadyAdded: '{user} is been already added',
  createSuccessMsg: '{text} created successfully.',
  updateSuccessMsg: '{text} updated successfully.',
  deleteSuccessMsg: '{text} deleted successfully.',
  addAtleastOneUser: 'Please add atleast one user.',
  noUserWithEmail: 'There is no user with {user} email',
  discardChanges: 'Please confirm you want to discard the changes',
  // My Groups -  create group -END
  
  duplicateEmail: 'Duplicate Email',
  notValidEmail: '{email} is not a valid email',
  mailSentSuccessfully: 'Mail sent successfully',
  invitationsSentMsg: 'Invitations sent successfully',
  singleInvitationSentMsg: 'Invitation sent successfully',
  deactivateEmail: 'Are you sure you want to deactivate {email}?',
  deleteWarningMsg: 'Are you sure you want to delete this {text}?',
  removeWarningMsg: 'Are you sure you want to remove the changes?',
  usersAlreadyExist: 'The below users are already exist\n {emails}',
  areYouSureWarningMsg: 'Are you sure you want to {event} this {text}?',
  unableToInvite:
    'Unable to invite the following users. Please Try again:\n {emails}',
  // Invite-users -END

  // Auth
  someServerIssue: 'Some server issue.',
  loginSuccessMsg: 'Successfully logged in',
  passcodeResentMsg: 'Passcode re-sent successfully',
  enterPasscodeLabel: 'Please Enter 6 digit Passcode',
  informationSentMsg: 'Information sent successfully',
  enterPasscode: 'Enter six digit code which is been sent to email.',
  serverIssue: 'There is some server error, Please try afer sometime.',
  verificationEmailSuccessMsg:
    'Verification link has been sent to email id which you have entered.',
  verificationEmailMsg:
    'Verification mail will be sent to email id which you have entered once you click on register.',
  // Auth - END

  // tenant configuration:
  hasSingleSignOn: 'Has Single Sign on',
  canChangePassword: 'Has change password',
  hasForgotPassword: 'Has forgot password',
  hasProfile: 'Has Profile',
  changePasswordEndPoint:
    'End point to hit when user is requesting for change password',
  singleSignOnEndPoint: 'End point to hit when user is requesting to login',
  forgotPasswordEndPoint:
    'End point to hit when user is requesting for forgot password',
  // Nav header
  abortFileUploadWarningMsg: 'Do you want to abort file upload and logout?',
  // no data found
  loading: 'Loading ...',
  noDataFound: 'No data found',
  copyToClipboard: 'Link Copied to clipboard',
  reorderedSuccessfully: 'Reordered successfully',
  searchFromRecords: 'Search from {total} records',
  shareToYourSelfMsg: 'You cannot share to yourself',
  commaSeperated: 'You can add comma seperated emails.',
  fileNotSupported: '{type} file type is not supported.',
  supportedBrowsersMsg:
    'Sorry your Browser is not supported, below are supported browsers.',
  enteredSomethingInUserField:
    'You have entered something in {field} field, please clear/add that {field} before creating or updating group',
  pleaseEnterAmount: 'Please enter specific amount',
  unauthorizedAccess: 'Unauthorized access',
  linkExpired: 'The link has been expired.',
  selectedFiles: 'Selected Files',
  isRequired: 'The {field} is required.',
  charactersValidationError:
    'The {field} field may not be greater than {chars} characters',
  videoUploadWarningMsg: 'It will take some time to upload {size} file',
  submitCaseSuccessMsg: 'Please check your email and click on verify link to complete email verification process',
  CaseRequestApprovedMsg: 'User request has been approved',
  CaseRequestRejectedMsg: 'User request has been rejected',
  submitContactUsSuccessMsg: 'Your request has been submitted',

  noteUploadImgTumorConfig: 'Note: Upload 1920 x 900 pixels image',
  fillTumorType: 'Please fill Tumor Type before adding',
  fillTumorSubType: 'Please fill Tumor Sub Type before adding',
  updateTumorSite: 'Updated Tumor Site Successfully',
  updateSpecificCohort: 'Updated Specific Cohort Successfully',
  addSpecificCohort: 'Added Specific Cohort Successfully',
  submitCorrespondenceSuccessMsg: 'Your message was sent to Chairperson.',
  checkCountryAndCityExists: 'The combination of country and city does not match, try with the city of the selected country or try with the country of the entered city.',
  userAlreadyExist: 'The below user is already exist\n {emails}',
  itemAlreadyExist: 'The selected item is already exist',
  savedSuccessfully: 'Saved Successfully',
  comingSoon: 'COMING SOON!',
  comingSoonTumor: 'Breast Cancer Registry is currently under construction and will become available to all users in 2025. <br><br> Stay tuned for updates by following the news section on this website and our social media pages.',
  thumbnailUpdated: 'Thumbnail Updated Successfully',
  comingSoonTumorLung: 'Lung Cancer Registry is currently under construction and will become available to all users in 2025. <br><br> Stay tuned for updates by following the news section on this website and our social media pages.',
};
