import { getGLobalRepoUrl } from "./api-services";
import urls from './urls';
import axios from 'axios';
import { $session } from '@/helpers';

export const getAllMetadataListFact = (id) => axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.allMetadata}/${id}`));

export const getAllGeographicregionsFact = () => 
{
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(urls.metadatalist + '/' + urls.geographicregions),{
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
  });
}

export const getAlltumorsitesWithSearchFact =  (params) => axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.getAllTumorSites}`),{
  params,
});

export const getAlltumorsitesFact = () => 
{
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.tumorsites}`),{
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
  });
}

export const getAllTumortypesFact = id =>
{
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.tumortypes}/${id}`), {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
  });
}

export const getAllTumorSubTypesFact = id => 
{
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.tumorsubtypes}/${id}`), {
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
  });
}

export const getTumorsiteFact = id => axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.tumorsite}/${id}`));
export const addTumorSiteFact = data => axios.post(getGLobalRepoUrl(`${urls.metadatalist}${urls.addTumorSite}`), data);
export const updateTumorSiteFact = data => axios.put(getGLobalRepoUrl(`${urls.metadatalist}${urls.updateTumorSite}`), data);
export const deleteTumorSiteFact = id => axios.delete(getGLobalRepoUrl(`${urls.metadatalist}${urls.deleteTumorsite}/${id}`));

export const addTumorTypeFact = data => axios.post(getGLobalRepoUrl(`${urls.metadatalist}${urls.addTumorType}`), data);
export const updateTumorTypeFact = data => axios.put(getGLobalRepoUrl(`${urls.metadatalist}${urls.updateTumorType}`), data);
export const deleteTumorTypeFact = id => axios.delete(getGLobalRepoUrl(`${urls.metadatalist}${urls.deleteTumorType}/${id}`));

export const addTumorSubTypeFact = data => axios.post(getGLobalRepoUrl(`${urls.metadatalist}${urls.addTumorSubType}`), data);
export const updateTumorSubTypeFact = data => axios.put(getGLobalRepoUrl(`${urls.metadatalist}${urls.updateTumorSubType}`), data);
export const deleteTumorSubTypeFact = id => axios.delete(getGLobalRepoUrl(`${urls.metadatalist}${urls.deleteTumorSubType}/${id}`));

export const getAllSpecificCohortsWithSearchFact =  (params) => axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.getAllSpecificCohorts}`),{
  params,
});

export const getAllSpecificCohortsFact = () => 
{
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.specificCohorts}`),{
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
  });
}

export const getSpecificCohortFact = id => axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.specificCohort}/${id}`));
export const addSpecificCohortFact = data => axios.post(getGLobalRepoUrl(`${urls.metadatalist}${urls.addSpecificCohort}`), data);
export const updateSpecificCohortFact = data => axios.put(getGLobalRepoUrl(`${urls.metadatalist}${urls.updateSpecificCohort}`), data);
export const deleteSpecificCohortFact = id => axios.delete(getGLobalRepoUrl(`${urls.metadatalist}${urls.deleteSpecificCohort}/${id}`));


export const getAllSpecificCohortsByTumorFact = (id) => 
{
  const tenantData =$session.getTenantData();
  return axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.specificCohortByTumorSite}/${id}`),{
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
  });
}

export const getCaseReviewCommentFact = params => axios.get(getGLobalRepoUrl(`${urls.metadatalist}${urls.caseReviewComment}`), {
  params,
});

export const addCaseReviewCommentFact = data => axios.post(getGLobalRepoUrl(`${urls.metadatalist}${urls.addCaseReviewComment}`), data);

export const calculateProbabilities = (id, params) => 
{
  const tenantData = $session.getTenantData();
  return axios.post(getGLobalRepoUrl(`${urls.metadatalist}${urls.calculateProbabilities}/${id}`), params,{
    headers: {
      'X-TenantID': tenantData && tenantData.id ? tenantData.id : null,
    }
  });
}
